import { Environment } from './environment.interface';

export const environment: Environment = {
	// BASICS
	name: 'staging',
	production: true,

	// URLS
	api_url: 'https://engage-api.clickdealer.guru/',
	search_api_url: 'https://search.click-engage.co.uk/',
	vehicle_image_url: 'https://d1t0ec94wfw46m.cloudfront.net/',
	brand_image_url: 'https://images.clickdealer.co.uk/brands/',
	deploy_protocol: 'https://',
	deploy_domain: 'engage.clickdealer.guru',
	website_domain: 'https://websites.clickdealer.guru',
	evolution_idd_url: 'https://www.evolutionfunding.com/public/initial-disclosure-document/',
	motion_idd_url: 'https://uat.mycreditnet.co.uk/Register/GetInitialDisclosureDocument',

	// KEYS
	pusher_key: '3a24f34113e452186e01',
	loqate_api_key: 'EU18-UZ17-YX88-NW85',

	// VISITOR CHAT
	visitor_chat_account_id: 4738,
	visitor_chat_domain: 'www.clickmotors.co.uk',

	// HELLOSIGN
	hellosign_client_id: '9c40ae73fa87fec8dc29858e2bfc09de',

	// GTM SETTINGS
	gtm_container_id: 'GTM-WC3R83P',
	gtm_environment_id: '58',
	gtm_environment_auth: '7g_yr4jotQpd7kQXrHo92w',

	// APM SETTINGS
	apm_name: 'stage',
};
